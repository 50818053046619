var myElement = document.querySelector(".global-header");
var options = {
    // vertical offset in px before element is first unpinned
    offset : 50,
    // or you can specify offset individually for up/down scroll
    offset: {
        up: 100,
        down: 50
    },
    // scroll tolerance in px before state changes
    tolerance : 0,
    // or you can specify tolerance individually for up/down scroll
    tolerance : {
        up : 5,
        down : 0
    },
    // css classes to apply
    classes : {
        // when element is initialised
        initial : "global-header",
        // when scrolling up
        pinned : "global-header--pinned",
        // when scrolling down
        unpinned : "global-header--unpinned",
        // when above offset
        top : "global-header--top",
        // when below offset
        notTop : "global-header--not-top",
        // when at bottom of scroll area
        bottom : "global-header--bottom",
        // when not at bottom of scroll area
        notBottom : "global-header--not-bottom",
        // when frozen method has been called
        frozen: "global-header--frozen",
        // multiple classes are also supported with a space-separated list
        pinned: "global-header--pinned"
    },
};
var headroom = new Headroom(myElement, options);
headroom.init();
