function animateFrom(elem, direction) {

	direction = direction || 1;
	var x = 0,
	y = direction * 100;

	if(elem.classList.contains("from-left")) {
		x = -100;
		y = 0;
	} else if (elem.classList.contains("from-right")) {
		x = 100;
		y = 0;
	} else if (elem.classList.contains("from-bottom")) {
		x = 0;
		y = 100;
	}

	elem.style.transform = "translate(" + x + "px, " + y + "px)";
	elem.style.opacity = "0";

	gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
		duration: 1.5,
		x: 0,
		y: 0,
		autoAlpha: 1,
		ease: "expo",
		overwrite: "auto"
	});

}

function hide(elem) {
	gsap.set(elem, {autoAlpha: 0});
}

document.addEventListener("DOMContentLoaded", function() {
	gsap.registerPlugin(ScrollTrigger);

	gsap.utils.toArray(".animate").forEach(function(elem) {
		hide(elem); // assure that the element is hidden when scrolled into view

		ScrollTrigger.create({
			trigger: elem,
			// start: "bottom bottom",
			onEnter: function() { animateFrom(elem) },
			onEnterBack: function() { animateFrom(elem, -1) },
			onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
		});
	});
});

/* Panels 
const panelsContainer = document.querySelector(".aeb-block-side-scroller");
const panels = gsap.utils.toArray(".aeb-block-side-scroller__slide");
gsap.to(panels, {
  xPercent: -100 * ( panels.length - 1 ),
  ease: "none",
  scrollTrigger: {
    trigger: ".aeb-block-side-scroller",
    pin: true,
    start: "top top",
    scrub: 1,
    snap: {
      snapTo: 1 / ( panels.length - 1 ),
      duration: {min: 0.1, max: 0.1}
    },
    end: () => "+=" + (panelsContainer.offsetWidth - innerWidth)
  }
});*/